@import './shared/variables';
@import './shared/mixins';
@import './shared/placeholders';

.iconButton {
  background: none;
  border: none;
  outline: none;
  font-size: 2rem;
  color: $text-primary;
  cursor: pointer;
}

.whiteIconButton {
  color: $text-secondary;
}

.closeMobileNav {
  @extend .whiteIconButton;
  position: absolute;
  top: 10px;
  right: 30px;
}

.primaryButton {
  @extend %button;
  background: $background-light;
  color: $text-primary;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: $background;
    color: $text-secondary;
  }
}

.secondaryButton {
  @extend %button;
  background: none;
  color: $text-secondary;
  border: $bold-border;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    color: $text-primary;
    border: $bold-dark-border;
  }
}

.accentButton {
  @extend %button;
  background: $linear-gradient;
  color: $text-primary;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    color: $text-secondary;
  }
}

.modalButton {
  @extend .accentButton;
  width: 100%;
}
