@import "./shared/variables";

.footer {
  position: relative;
  background: $background;

  &::before {
    content: '';
    position: absolute;
    top: -1.5rem;
    width: 80%;
    left: 10%;
    height: 3px;
    background: $background-light;
  }
}

.copyright {
  text-align: center;
  font-size: $bigger;
  color: $text-secondary;
  padding: 1.5rem;
}

.iconLink {
  color: $text-secondary;
  margin: 0 2rem;
  cursor: pointer;
  transition: $transition;

  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    color: $accent-color;
  }
}
