@import "./shared/mixins";
@import "./shared/placeholders";

.workPage {
  @extend %section;
}

.workItem {
  height: 230px;
  flex-basis: 90%;
  border-radius: $border-radius;
  background: $background;
  margin: 1rem .8rem;
  cursor: pointer;

  @include small-screen {
    flex-basis: 45%;
  }

  @include large-screen {
    flex-basis: 30%;
  }

  img {
    width: 100%;
    max-width: 400px;
    height: 100%;
    display: block;
    margin: 0 auto;
  }
}

.overlay {
  position: absolute;
  bottom: -1px;
  z-index: -1;
}
