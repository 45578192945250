@import './shared/variables';
@import "./shared/placeholders";
@import "./shared/mixins";

.title {
  position: relative;

  h2 {
    color: $text-primary;
    letter-spacing: 2px;
    font-size: 2rem;
    font-family: $secondary-font;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3rem;

    @include medium-screen {
      font-size: 2rem;
    }
  }

  &::after {
    content: '';
    width: 60%;
    height: 2px;
    background: $background;
    position: absolute;
    bottom: -.5rem;
    left: 50%;
    transform: translateX(-50%);

    @include medium-screen {
      width: 30%;
    }
  }
}

.whiteTitle {
  h2 {
    color: $text-secondary!important;
  }

  &::after {
    background: $background-light;
  }
}
