// Colors
$background-light: #fff;
$background: #6E6E6E;
$background-overlay: rgba(0,0,0,.7);
$background-overlay-light: rgba(255,255,255,.2);
$text-primary: #5f5f5f;
$text-secondary: #ffffff;
$accent-color: #FFD102;
$danger: #C50903;
$error: #ff0000;
$success: #068e08;
$radial-gradient:  radial-gradient(50% 50% at 50% 50%, #FFD102 0%, #FFAE4C 100%);
$linear-vertical-gradient:  linear-gradient(180deg, #FFD102 0%, #FFAE4C 100%);;
$linear-gradient:  linear-gradient(91.53deg, #FFD102 0%, #FFAE4C 100%);;

// Borders
$accent-bold-border: 3px solid $accent-color;
$bold-border: 2px solid $background-light;
$bold-dark-border: 2px solid $background;
$white-border: 1px solid $background-light;
$border-radius: 8px;

// Fonts
$secondary-font: Cinzel, serif;
$primary-font: Nunito, 'sans-serif';
$bigger: 1.1rem;
$smaller: .9rem;

// Breakpoints
$sm: 600px;
$md: 950px;
$lg: 1200px;

//Transition
$transition: all .5s ease;
