@import "variables";
@import "./mixins";

%button {
  display: block;
  width: 350px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: .8rem;
  font-size: 1rem;
  outline: none;
  border: none;
  line-height: 1rem;
  cursor: pointer;
  transition: $transition;
  border-radius: $border-radius;
  font-family: $primary-font;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;

  &:hover {
    cursor: pointer;
  }
}

%title {
  color: $text-secondary;
  letter-spacing: 2px;
}

%section {
  min-height: calc(100vh - 100px);
  padding: 100px 0;
  position: relative;
}

%fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
}

%input {
  display: block;
  width: 100%;
  padding: 1rem;
  margin: .3rem auto;
  font-size: $bigger;
  outline: none;
  border: $white-border;
  border-radius: $border-radius;
  background: transparent;
  color: $text-secondary;

  &:focus {
    border: $bold-border;
  }

  &::placeholder {
    color: #b4b4b4;
  }
}
