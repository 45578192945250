@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Nunito&family=Playfair+Display&display=swap');
@import "./shared/variables";
@import "./shared/placeholders";
@import "Buttons.module";

.contactPage {
  @extend  %section;
  background: $background;
}

.form {
  padding: 2rem;
  width: 100%;
  margin: 0 auto;

  @include small-screen {
    width: 90%;
  }

  @include medium-screen {
    width: 80%;
  }

  @include large-screen {
    width: 60%;
  }
}

.input {
  @extend %input;
}

.inputError {
  border: 1px solid $error;
}

.textarea {
  @extend %input;
  font-family: Montserrat, sans-serif;
  resize: none;
}

.inputGroup {
  margin: 1.5rem auto;
}

.formError {
  font-size: $smaller;
  color: $text-secondary;
  padding: .4rem 1.2rem;
  font-weight: 200;
}

.confirmationButton {
  @extend .accentButton;
  width: 100%;
  margin-top: .5rem;

  @include medium-screen {
    width: 350px;
    margin: .5rem 0 .5rem auto;
  }
}
