@import "./shared/variables";
@import "./shared/placeholders";
@import "./shared/mixins";


.mainPage {
  @extend %section;
  background: $radial-gradient;
}

.mainColumn {
  flex: 1 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include medium-screen {
    flex: 1 0 45%;
    order: 1
  }
}

.imgColumn {
  flex: 1 0 100%;

  @include medium-screen {
    flex: 1 0 45%;
    order: 2
  }
}

.mainPageTitle {
  @extend %title;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @include large-screen {
    font-size: 2.5rem;
  }
}

.mainPageSubtitle {
  @extend %title;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin-top: 2rem;

  @include medium-screen {
    margin-top: 0;
    font-size: 1.5rem;
  }
}

.actionButtons {
  margin-top: 1rem;
}

.overlay {
  position: absolute;
  bottom: -1px;
}

.homePageImg {
  width: 40%;
  display: block;
  margin: 0 auto;

  @include medium-screen {
    width: 80%;
  }
}

.logoBackground {
  position: absolute;
  top: 0;
  left: 0;
}
