@import "./shared/mixins";
@import "./shared/variables";
@import "./shared/placeholders";

.skillsPage {
  @extend %section;
  min-height: calc(60vh - 200px);
  background: $linear-vertical-gradient;

  &::after {
    content: '';
    position: absolute;
    bottom: -30px;
    width: 100%;
    height: 30px;
    background: linear-gradient(180deg, #FFAE4C 0%, rgba(255, 255, 255, 0) 100%);;
  }
}

.slider {
  overflow-x: hidden;
}

.skill {
  display: block;
  width: 100px;
  height: 100px;
  fill: $text-primary;
}
