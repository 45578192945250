@import "variables";

@mixin small-screen {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin medium-screen {
  @media (min-width: $md) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin nav-item($display, $hover-color) {
  @content;

  list-style-type: none;
  display: $display;
  color: $text-secondary;

  &:hover {
    cursor: pointer;
    color: $hover-color;
  }
}
