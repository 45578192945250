@import "./shared/placeholders";

.loaderWrapper {
  @extend %fixed;
  height: 100vh;
  background: $background-overlay;
}

.loader {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 8px solid $accent-color;
  border-top-color: transparent;
}
