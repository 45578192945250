@import "./shared/mixins";
@import "./shared/variables";
@import "./shared/placeholders";

.aboutPage {
  @extend %section;
}

.textColumn {
  flex: 1 0 100%;
  color: $text-primary;
  padding: 2.5rem;

  @include medium-screen {
    flex: 1 0 45%;
    order: 1;
  }

  p {
    margin-bottom: 1rem;
    font-size: $bigger;
    line-height: 1.5rem;
  }
}

.imgColumn {
  flex: 1 0 100%;

  @include medium-screen {
    flex: 1 0 45%;
    order: 2;
  }
}

.image {
  width: 250px;
  display: block;
  margin: 0 auto;

  @include large-screen {
    width: 350px;
  }
}

.overlay {
  position: absolute;
  bottom: -1px;
  z-index: -1;
}
