@import './shared/variables';
@import './shared/mixins';

.modal {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.6);
  z-index: 200;
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background: $background-light;
  height: 80%;
  border-radius: $border-radius;

  @include large-screen {
    width: 60%;
  }
}

.modalBody {
  padding: 2rem;
}

.modalItem {
  flex: 0 1 90%;
  margin: 1rem auto;

  @include medium-screen {
    flex: 0 1 45%;
  }
}

.descriptionColumn {
  @extend .modalItem;
  @include medium-screen {
    order: 1
  }
}

.overviewColumn {
  @extend .modalItem;
  @include medium-screen {
    order: 2
  }
}

.technologiesColumn {
  @extend .modalItem;
  @include medium-screen {
    order: 3
  }
}

.actionsColumn {
  @extend .modalItem;
  @include medium-screen {
    order: 4
  }
}


.modalSkill {
  display: block;
  width: 55px;
  height: 55px;
  fill: $text-primary;
  margin: .8rem;
}

.closeModal {
  position: absolute;
  top: 10px;
  right: 10px;
}

.overviewImgWrapper {
  height: 250px;
  text-align: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
