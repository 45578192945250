@import './shared/variables';
@import './shared/mixins';
@import "./shared/placeholders";

.navbar {
  @extend %fixed;
  height: 80px;
  align-items: center;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 10px 10px;
  background: transparent;
  transition: $transition;
}

.backgroundNavbar {
  background: $background;
  border-bottom: $accent-bold-border;

  .navItem {
    &:hover {
      cursor: pointer;
      color: $accent-color;
    }
  }
}

.navContent {
  display: flex;
}

.navItem {
  @include nav-item(inline-block, $text-primary);
  margin: 0 2rem;
}

.mobileNavContent {
  @extend %fixed;
  padding: 1rem;
  height: 100vh;
  background: $background;
  z-index: 2;

  ul {
    position: absolute;
    top: 77px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobileNavItem {
  @include nav-item(block, $accent-color);
  margin: 0 0 3rem;
}
